import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});

export default function Termsofuse(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">
                    <div className="content_wrap_wrapper">
                        <article className="page_article terms_article">
                            <h1 className="page_article__header upper" id="top"><span
                                className="page_article__header_text">Terms Of Services</span></h1>
                            <ul className="terms_article__toc">
                                <li className="terms_article__toc_item"><a href="#terms_and_conditions"
                                                                           className="terms_article__toc_item_link">Terms
                                    and Conditions</a>
                                </li>
                                <li className="terms_article__toc_item"><a href="#no_warranty"
                                                                           className="terms_article__toc_item_link">Stock4Services,
                                    Inc. Makes No Warranty</a></li>
                                <li className="terms_article__toc_item"><a href="#liability_limitation"
                                                                           className="terms_article__toc_item_link">Liability
                                    Limitation; Your
                                    Exclusive Remedy</a></li>
                                <li className="terms_article__toc_item"><a href="#acknowledgement_and_agreement"
                                                                           className="terms_article__toc_item_link">ACKNOWLEDGEMENT
                                    AND
                                    AGREEMENT</a></li>
                                <li className="terms_article__toc_item"><a href="#changes_to_the_websites"
                                                                           className="terms_article__toc_item_link">Changes
                                    to the
                                    Services4Stock Web Sites; Additional Liability Limitation</a></li>
                                <li className="terms_article__toc_item"><a href="#service_fees"
                                                                           className="terms_article__toc_item_link">Service
                                    Fees and Penalties</a></li>
                                <li className="terms_article__toc_item"><a href="#choice_of_legal_forum_selection"
                                                                           className="terms_article__toc_item_link">Choice
                                    of Legal Forum
                                    Selection</a></li>
                            </ul>
                        </article>
                        <article className="page_article terms_article" id="terms_and_conditions">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Terms and Conditions</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    This agreement describes your rights and responsibilities. Please read it carefully.
                                    It is highly
                                    recommended that you consult a licensed financial advisor or broker before making
                                    any and all
                                    investment decisions.
                                </p>
                                <p>
                                    All contents of the service are provided for information purposes only and are
                                    provided without
                                    warranty of any kind. In no event will we be liable for any direct, indirect,
                                    consequential, or
                                    incidental damages arising out of any decision made or action taken by you in
                                    reliance on the
                                    service, whether or not caused in whole or part by our negligence.
                                </p>
                                <p>
                                    By posting a business plan, business opportunity or message, uploading a file, or
                                    engaging in any
                                    other form of communication through the Services4Stock website, you are granting
                                    Stock4Services,
                                    Inc. a perpetual, royalty-free and irrevocable right and license to use, reproduce,
                                    modify, adapt,
                                    publish, translate, distribute, transmit, publicly display, publicly perform,
                                    sublicense, create
                                    derivative works from, transfer, and sell any such plan, opportunity, messages,
                                    files, or
                                    communications.
                                </p>
                                <p>
                                    All information provided by the service is obtained from sources believed to be
                                    accurate and
                                    reliable. However, due to the number of sources from which information on the
                                    service is obtained,
                                    and the inherent hazards of electronic distribution, there may be delays, omissions,
                                    or inaccuracies
                                    in such information. We do not warranty the accuracy, completeness, correctness or
                                    fitness for any
                                    particular purpose of the information available through the service. This site does
                                    not make any
                                    offer to solicit, deal in, sell or dispose of any security for valuable
                                    consideration, and does not
                                    give advice for the purpose of buying and selling a security.
                                </p>
                                <p>
                                    Any redistribution of the information, images, or intellectual property presented
                                    and or contained
                                    within this website without prior written consent from MANAGEMENT is strictly
                                    prohibited.
                                </p>
                                <p>
                                    Stock4Services, Inc. makes no representations or warrantees, expressed or implied,
                                    as to the
                                    accuracy, completeness, or fitness for any purpose or use of the Information
                                    presented on the
                                    Website or made available from any or all of the products, services, internet web
                                    sites owned and
                                    operated by Stock4Services, Inc. Stock4Services, Inc. does not warrant that the
                                    supply of
                                    Information will be uninterrupted, error free, or not terminated altogether, and
                                    will not be liable
                                    for any direct, indirect, or consequential loss arising from any use of or reliance
                                    on the
                                    information and its availability.
                                </p>
                                <p>
                                    Stock4Services, Inc. does not warrant or make any representations regarding the use
                                    or the results
                                    of the use of the information and materials on this website in terms of their
                                    correctness, accuracy,
                                    reliability, profitability, or otherwise. The information and commentaries are not
                                    meant to be
                                    endorsements or offerings of any securities, commodities, financial objects,
                                    futures, options,
                                    stocks or other investment vehicles.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="no_warranty">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Stock4Services, Inc. Makes No Warranty</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    STOCK4SERVICES, INC. PROVIDES THE SERVICES4STOCK WEBSITES "AS IS," "WITH ALL
                                    FAULTS," AND "AS
                                    AVAILABLE," AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY,
                                    AND EFFORT IS
                                    WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE STOCK4SERVICES,
                                    INC. PARTIES MAKE
                                    NO REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED. THE
                                    STOCK4SERVICES, INC. PARTIES
                                    DISCLAIM ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED,
                                    INCLUDING WITHOUT
                                    LIMITATION (1) WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                    PURPOSE,
                                    WORKMANLIKE EFFORT, ACCURACY, TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND
                                    NON-INFRINGEMENT, (2) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING OR
                                    USAGE OF TRADE,
                                    AND (3) WARRANTIES OR CONDITIONS THAT ACCESS TO OR USE OF THE SERVICES4STOCK
                                    WEBSITES WILL BE
                                    UNINTERRUPTED; OR ERROR-FREE. THERE ARE NO WARRANTIES THAT EXTEND BEYOND THE FACE OF
                                    THIS AGREEMENT.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="liability_limitation">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Liability Limitation; Your Exclusive Remedy</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    IN NO EVENT WILL ANY STOCK4SERVICES, INC. PARTY BE LIABLE FOR ANY DAMAGES, INCLUDING
                                    WITHOUT
                                    LIMITATION ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES
                                    ARISING OUT OF,
                                    BASED ON, OR RESULTING FROM THIS AGREEMENT OR YOUR USE OF THE SERVICES4STOCK
                                    WEBSITES, EVEN IF SUCH
                                    STOCK4SERVICES, INC. PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
                                    EXCLUSION OF
                                    DAMAGES UNDER SECTION 10 IS INDEPENDENT OF YOUR EXCLUSIVE REMEDY AND SURVIVES IN THE
                                    EVENT SUCH
                                    REMEDY FAILS ITS ESSENTIAL PURPOSE OR IS OTHERWISE DEEMED UNENFORCEABLE. THESE
                                    LIMITATIONS AND
                                    EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (1) BREACH OF
                                    CONTRACT, (2) BREACH
                                    OF WARRANTY, (3) NEGLIGENCE, OR (4) ANY OTHER CAUSE OF ACTION, TO THE EXTENT SUCH
                                    EXCLUSION AND
                                    LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW. IF YOU ARE DISSATISFIED WITH THE
                                    SERVICES4STOCK
                                    WEBSITES, YOU DO NOT AGREE WITH ANY PART OF THIS AGREEMENT, OR YOU HAVE ANY OTHER
                                    DISPUTE OR CLAIM
                                    WITH OR AGAINST ANY STOCK4SERVICES, INC. PARTY WITH RESPECT TO THIS AGREEMENT OR THE
                                    SERVICES4STOCK
                                    WEBSITES, THEN YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
                                    SERVICES4STOCK WEBSITES.
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Services4Stock, also referred to as Us, We,
                                    Services4Stock.com. You and
                                    Your refers to The Subscriber to Services4Stock.com service(s).
                                </p>
                                <p>
                                    All information and services originating from Stock4Services, Inc., DBA
                                    Services4Stock, is for
                                    informational purposes only. Services4Stock services, statements, information,
                                    advertising,
                                    promotions, educational information, and any and all website information, fall under
                                    the First
                                    Amendment Right to Freedom of Speech. Services4Stock does not offer or provide
                                    personalized advice.
                                    We facilitate the exchange of information. The information that we provide either by
                                    email,
                                    Internet, or from our website, is not intended to be, and should not be construed in
                                    any manner
                                    whatsoever as, personalized advice.
                                </p>
                                <p>
                                    Services4Stock, its employees, staff, or editor assume no responsibility or
                                    liability for your
                                    selection and partnering results. Any indicators, strategies, commentaries, and/or
                                    all other
                                    features are for informational and educational purposes only, and should not be
                                    construed as
                                    investment or trading advice.
                                </p>
                                <p>
                                    Stock4Services, Inc., DBA Services4Stock, does not in any way warrant or guarantee
                                    the success of
                                    any action, which you take in reliance on our statements. Stock4Services, Inc., DBA
                                    Services4Stock,
                                    liability, whether in contract, tort, negligence, or otherwise, shall be limited in
                                    the aggregate to
                                    direct and actual damages not to exceed the fees received by or from the subscriber.
                                    I, the
                                    undersigned, agree that Stock4Services, Inc., DBA Services4Stock, and all and any
                                    employees of
                                    Stock4Services, Inc., DBA Services4Stock, will not and can not be liable for
                                    consequential,
                                    incidental, punitive, special, exemplary, or indirect damages resulting directly or
                                    indirectly from
                                    the use of or reliance upon any material provided by Stock4Services, Inc., DBA
                                    Services4Stock and
                                    all and any employees of Stock4Services, Inc., DBA Services4Stock. Without
                                    limitation,
                                    Stock4Services, Inc., DBA Services4Stock, and all and any employees of
                                    Stock4Services, Inc., DBA
                                    Services4Stock, will not and can not and shall not be responsible or liable for any
                                    loss or damages
                                    related to, either directly or indirectly, (1) any guarantee of being selected by
                                    one or more media
                                    service providers; (2) a subscriber's inability to use or any delay in accessing
                                    Stock4Services,
                                    Inc., DBA Services4Stock website or any other source of material provided by
                                    Stock4Services, Inc.,
                                    DBA Services4Stock, including, but not limited to email; (3) any absence of material
                                    on
                                    Stock4Services, Inc., DBA Services4Stock website or email; (4) Stock4Services, Inc.,
                                    DBA
                                    Services4Stock failure to deliver or delay in delivering any material or (5) any
                                    kind of error in
                                    transmission of material. Stock4Services, Inc., DBA Services4Stock and Subscriber
                                    acknowledge that,
                                    without limitation, the above-enumerated conditions cannot be the probable result of
                                    any breach of
                                    any agreement between Stock4Services, Inc., DBA Services4Stock and Subscriber.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="acknowledgement_and_agreement">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">ACKNOWLEDGEMENT AND AGREEMENT</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    Notwithstanding any other agreement or other communications between Stock4Services,
                                    Inc., DBA
                                    Services4Stock and Subscriber to the contrary, receipt or use of any material
                                    provided by
                                    Stock4Services, Inc., DBA Services4Stock, at any time and through any means, whether
                                    directly or
                                    indirectly, represents acknowledgement by such person of this disclaimer and
                                    agreement with its
                                    terms and conditions. Stock4Services, Inc., DBA Services4Stock makes no
                                    representations or
                                    warranties about the accuracy or completeness of the information contained on this
                                    website,
                                    services, alerts, reports, or in emails.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="changes_to_the_websites">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Changes to the Services4Stock Web Sites; Additional Liability Limitation</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    THE STOCK4SERVICES, INC. PARTIES MAY CHANGE THE SERVICES4STOCK WEBSITES OR DELETE
                                    FEATURES IN ANY
                                    WAY, AT ANY TIME AND FOR ANY REASON. As you use the SERVICES4STOCK websites, you
                                    should expect to
                                    receive, access or use information, materials, graphics, software, data, and content
                                    (collectively,
                                    "Content") originated by Stock4Services, Inc. and persons other than Stock4Services,
                                    Inc. (any such
                                    person is referred to as a "Third Party"). WITHOUT LIMITING THE GENERALITY OF
                                    SECTIONS 9 AND 10, YOU
                                    ACKNOWLEDGE AND AGREE THAT THE STOCK4SERVICES, INC. PARTIES ARE NOT RESPONSIBLE OR
                                    LIABLE FOR (1)
                                    ANY CONTENT, INCLUDING WITHOUT LIMITATION, ANY INFRINGING, INACCURATE, OBSCENE,
                                    INDECENT,
                                    THREATENING, OFFENSIVE, DEFAMATORY, TORTIOUS, OR ILLEGAL CONTENT, OR (2) ANY THIRD
                                    PARTY CONDUCT,
                                    TRANSMISSIONS OR DATA. IN ADDITION, WITHOUT LIMITING THE GENERALITY OF SECTIONS 9
                                    AND 10, YOU
                                    ACKNOWLEDGE AND AGREE THAT STOCK4SERVICES, INC. IS NOT RESPONSIBLE OR LIABLE FOR (1)
                                    ANY VIRUSES OR
                                    OTHER DISABLING FEATURES THAT AFFECT YOUR ACCESS TO OR USE OF THE SERVICES4STOCK
                                    WEBSITES, (2) ANY
                                    INCOMPATIBILITY BETWEEN THE SERVICES4STOCK WEBSITES AND OTHER WEBSITES, SERVICES,
                                    SOFTWARE AND
                                    HARDWARE, (3) ANY DELAYS OR FAILURES YOU MAY EXPERIENCE IN INITIATING, CONDUCTING OR
                                    COMPLETING ANY
                                    TRANSMISSIONS OR TRANSACTIONS IN CONNECTION WITH THE SERVICES4STOCK WEBSITES IN AN
                                    ACCURATE OR
                                    TIMELY MANNER, OR (4) ANY DAMAGES OR COSTS OF ANY TYPE ARISING OUT OF OR IN ANY WAY
                                    CONNECTED WITH
                                    YOUR USE OF ANY SERVICES AVAILABLE FROM THIRD PARTIES THOUGH LINKS CONTAINED ON THE
                                    SERVICES4STOCK
                                    WEBSITES. THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN SECTIONS 9, 10, AND 11 OF
                                    THIS AGREEMENT
                                    APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AND ARE NOT INTENDED TO
                                    DEPRIVE YOU OF ANY
                                    MANDATORY PROTECTIONS PROVIDED TO YOU UNDER APPLICABLE LAW.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="service_fees">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Service Fees and Penalties</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    Each subscriber will be liable for a monthly fee for services of $49.95, and can be
                                    terminated at
                                    any time.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                        <article className="page_article terms_article" id="choice_of_legal_forum_selection">
                            <h2 className="page_article__subheader"><span className="page_article__subheader_text">Choice of Legal Forum Selection</span>
                            </h2>
                            <div className="page_article__text">
                                <p>
                                    You agree that any dispute arising out of or relating to these Terms or any content
                                    posted to a
                                    site, including copies and republication thereof, whether based in contract, tort,
                                    statutory or
                                    other law, will be governed by the laws of the State of Delaware, excluding its
                                    conflicts of law
                                    provisions. You further consent to the personal jurisdiction of and exclusive venue
                                    in the federal
                                    and state courts located in and serving Delaware as the legal forum for any such
                                    dispute.
                                </p>
                                <div className="terms_article__to_top"><a className="terms_article__to_top_link"
                                                                          href="#top">back to top</a>
                                </div>
                            </div>
                        </article>
                    </div>

                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}
import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});
export default function Faq(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">What is Services4Stock.com?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                <span className="s4s">Services4Stock.com</span> provides a unique service designed
                                specifically to
                                assist emerging companiesthat are looking for creative ways to grow their business.
                                Services4Stock.com provides these companies with just such a mechanism. By subscribing
                                to our
                                services, you have the potential to build your business and reach markets more quickly
                                and with less
                                cash outlay than expected. We match your company’s profile with leading media business
                                service
                                providers looking to invest in companies just like yours. They provide you with
                                high-profile media
                                exposure or other business services without asking for any cash payment. You compensate
                                the service
                                provider by giving them a small piece of equity in your company. It's simple: they
                                succeed only when
                                you succeed.
                            </p>
                        </div>
                    </Box>
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">How Does Services4Stock.com work?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                For a modest monthly fee, your company’s profile will be promoted and available to
                                leading,
                                high-profile media service providers. These companies provide services like advertising
                                in regional
                                and national media outlets; through print, radio and television, public relations
                                services and legal
                                services. We have developed a network of these pre-qualified service providers who
                                subscribe to our <a href="http://stock4services.com">Stock4Services.com</a> service. Our service
                                providers are
                                specifically looking for emerging companies such as yours to exchange their services for
                                equity in
                                your company.
                            </p>
                        </div>
                    </Box>
                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">How does it benefit my company?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                Well-orchestrated media exposure can make or break an emerging company. Expert legal
                                advice can
                                accelerate your production schedule. A savvy public relations campaign featuring a
                                well-known
                                celebrity can create buzz about your business. But all of these business services can be
                                very
                                expensive. <span className="s4s">Services4Stock.com</span> provides emerging companies
                                with a unique
                                alternative to paying for these services, facilitating the exchange of equity for
                                expertise. As a
                                subscriber to Services4Stock.com, you will benefit from the following:
                            </p>
                            <ul className="faq_article__list">
                                <li className="faq_article__list_item">You can turn a small piece of equity in your
                                    company into two
                                    proven growth strategy vehicles – advertising and promotion
                                </li>
                                <li className="faq_article__list_item">Your service providers become your equity
                                    partners, aligned with
                                    your business objectives, since they succeed only when you succeed
                                </li>
                                <li className="faq_article__list_item">Our large network of service providers enable you
                                    to achieve
                                    exposure in multiple markets and market segments
                                </li>
                                <li className="faq_article__list_item">Using our services reduces your burden of finding
                                    and acquiring
                                    outside funding for marketing and promotion
                                </li>
                            </ul>
                        </div>
                    </Box>

                    <Box sx={{padding: '0 0 40px'}}>
                        <h2 className="page_article__subheader"><span className="page_article__subheader_text">What are the fees?</span>
                        </h2>
                        <div className="page_article__text">
                            <p>
                                For the modest monthly fee of $49.95 per month, your company will be on the radar screen
                                of leading
                                media services providers looking to find companies such as yours. Your ongoing monthly
                                subscription
                                increases the likelihood of establishing new partnerships with one or more of these
                                media service
                                providers.
                            </p></div>
                    </Box>
                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import how1 from '../img/how/how1.png';
import how2 from '../img/how/how2.png';
import how3 from '../img/how/how3.png';
import Slider from "react-slick";


export default function How(){
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,

    };
    return(
        <Box className={'how__box'} id={'how'}>
            <h2>How does Services4Stock work?
            </h2>
            <Grid sx={{flexGrow: 1, marginTop: '45px', width: '100%', marginLeft: '0px'}} container spacing={3} className={'how__grid'}>
                <Grid item xs={4} className={'how__block'} >
                    <Box className={'how__col-img'}>
                        <img src={how1} alt=""/>
                        <span>01</span>
                    </Box>
                    <h3>Tell us what service you need
                    </h3>
                    <p>Complete the form below to tell us about your service needs (the more detail, the better),
                        and we’ll respond immediately. Whether it's online and outdoor media, trade shows,
                        celebrities, PR firms, online visibility agencies, legal services, sales expertise,
                        programming, or more, Services4Stock can handle it.
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 638.24 447.24" style={{enableBackground:'new 0 0 638.24 447.24'}}>
                        <linearGradient id="x5d083swha" gradientUnits="userSpaceOnUse" x1="23.999" y1="223.623" x2="613.41" y2="223.623">
                            <stop offset="0" style={{stopColor:'#ff9d00'}}/>
                            <stop offset=".993" style={{stopColor:'#ffc600'}}/>
                        </linearGradient>
                        <path d="M50.87 255.02c1.83.3 3.68.43 5.54.41h457.7l-6.88 3.2a63.81 63.81 0 0 0-18.08 12.8l-88.48 88.48c-11.65 11.12-13.61 29.02-4.64 42.4 10.44 14.26 30.46 17.35 44.72 6.91 1.15-.84 2.25-1.76 3.28-2.75l160-160c12.5-12.49 12.51-32.75.02-45.26l-.02-.02-160-160c-12.51-12.48-32.78-12.45-45.26.06-.98.98-1.9 2.03-2.74 3.14-8.97 13.38-7.01 31.28 4.64 42.4l88.32 88.64c4.69 4.7 10.09 8.64 16 11.68l9.6 4.32H58.18c-16.35-.61-30.69 10.81-33.76 26.88-2.84 17.45 9.01 33.88 26.45 36.71z" style={{fill:'url(#x5d083swha)'}}/>
                    </svg>
                </Grid>
                <Grid item xs={4} className={'how__block'}>
                    <Box className={'how__col-img'}>
                        <img src={how2} alt=""/>
                        <span>02</span>
                    </Box>
                    <h3>Meet your Personal Concierge to find your perfect service match
                    </h3>
                    <p>The moment we receive your request, your Personal Concierge will review it and connect with you
                        to answer any questions and form a better understanding of your exact needs. Then we'll
                        notify you with a status report on your service request within a few days.
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 638.24 447.24" style={{enableBackground:'new 0 0 638.24 447.24'}}>
                        <linearGradient id="x5d083swha" gradientUnits="userSpaceOnUse" x1="23.999" y1="223.623" x2="613.41" y2="223.623">
                            <stop offset="0" style={{stopColor:'#ff9d00'}}/>
                            <stop offset=".993" style={{stopColor:'#ffc600'}}/>
                        </linearGradient>
                        <path d="M50.87 255.02c1.83.3 3.68.43 5.54.41h457.7l-6.88 3.2a63.81 63.81 0 0 0-18.08 12.8l-88.48 88.48c-11.65 11.12-13.61 29.02-4.64 42.4 10.44 14.26 30.46 17.35 44.72 6.91 1.15-.84 2.25-1.76 3.28-2.75l160-160c12.5-12.49 12.51-32.75.02-45.26l-.02-.02-160-160c-12.51-12.48-32.78-12.45-45.26.06-.98.98-1.9 2.03-2.74 3.14-8.97 13.38-7.01 31.28 4.64 42.4l88.32 88.64c4.69 4.7 10.09 8.64 16 11.68l9.6 4.32H58.18c-16.35-.61-30.69 10.81-33.76 26.88-2.84 17.45 9.01 33.88 26.45 36.71z" style={{fill:'url(#x5d083swha)'}}/>
                    </svg>
                </Grid>
                <Grid item xs={4} className={'how__block'}>
                    <Box className={'how__col-img'}>
                        <img src={how3} alt=""/>
                        <span>03</span>
                    </Box>
                    <h3>Start working! Your new partner will become part of your team!
                    </h3>
                    <p>Choose from a curated selection of service providers who meet your needs and believe in your
                        business model. We’ll introduce you to your new partner, and they’ll start working as soon
                        as you say "go."
                    </p>
                </Grid>
            </Grid>
            <Slider {...settings} className={'how_slider'}>
                <Box className={'how__block'}>
                    <Box className={'how__col-img'}>
                        <img src={how1} alt=""/>
                        <span>01</span>
                    </Box>
                    <h3>Tell us what service you need
                    </h3>
                    <p>Complete the form below to tell us about your service needs (the more detail, the better),
                        and we’ll respond immediately. Whether it's online and outdoor media, trade shows, celebrities,
                        PR firms, online visibility agencies, legal services, sales expertise, programming, or more,
                        Services4Stock can handle it.
                    </p>
                </Box>
                <Box className={'how__block'}>
                    <Box className={'how__col-img'}>
                        <img src={how2} alt=""/>
                        <span>02</span>
                    </Box>
                    <h3>Meet your Personal Concierge to find your perfect service match
                    </h3>
                    <p>The moment we receive your request, your Personal Concierge will review it and connect
                        with you to answer any questions and form a better understanding of your exact needs.
                        Then we'll notify you with a status report on your service request within a few days.
                    </p>
                </Box>
                <Box className={'how__block'}>
                    <Box className={'how__col-img'}>
                        <img src={how3} alt=""/>
                        <span>03</span>
                    </Box>
                    <h3>Start working! Your new partner will become part of your team!
                    </h3>
                    <p>Choose from a curated selection of service providers who meet your needs and believe in your
                        business model. We’ll introduce you to your new partner, and they’ll start working as soon
                        as you say "go."
                    </p>
                </Box>
            </Slider>
        </Box>
    )
};
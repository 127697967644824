import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import banner from '../img/banner.jpg';
const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function Banner(){
    return(
        <Box className={'banner__box'}>
            <img src={banner} alt={''}/>
            <Box className={'banner__text'}>
                <h2>Land a valuable service partner
                    without spending any cash
                </h2>
                <p><strong>Turn your equity into a vehicle for your company’s future growth strategy. </strong>
                    S4S, the new way stocks are traded and services delivered.

                </p>
                <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">get started!</Button>
            </Box>
        </Box>
    )
};
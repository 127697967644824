import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import alex from '../img/news/alex.png';
import alexLena from '../img/news/alex_elena.png';
import news from '../img/news/newsusa.png';
import pdf from '../img/news/Legal_Times.pdf';
import pdf2 from '../img/news/Washington_Business_Journal.pdf';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});
export default function Inthenews(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className={"page_article aboutus_article"} maxWidth="lg">
                    <div className={"inthenews_page__header"}>
                        <div className={"content_wrap_wrapper"}>
                            <h2 className={"page_article__header"}>
                                <span className={"page_article__header_text"}>New Ideas in a Weak Economy</span>
                            </h2>
                            <p className={'inthenews_page__header_copy'}>Karen E. Klein, Los Angeles Times</p>
                        </div>
                    </div>
                    <div className="content_wrap_wrapper">
                        <article className="pge_article inthenews_article">
                            <div className="page_article__text">
                                <p>
                                    Look for opportunities to bring in revenue and cut costs where possible. For
                                    instance, if your cash
                                    flow decreases, look for innovative purchasing opportunities, said Estrea Dworkin
                                    Janoson, president
                                    of ProAd Solutions Inc. "<span className="s4s">Stock4Services.com</span> allows you
                                    to use company stock
                                    to buy services such as nationwide advertising," she said.
                                </p>
                                <p>
                                    Finally, don't panic. Smart businesses can turn a bad economy into a plus for their
                                    firms by taking
                                    advantage of lower prices and new opportunities for partnerships.
                                </p>
                            </div>
                        </article>
                    </div>
                    <div className="inthenews_page__header">
                        <div className="content_wrap_wrapper">
                            <h2 className="page_article__header"><span className="page_article__header_text">How to Earn a Hundred Million Dollars or More</span>
                            </h2>
                            <p className="inthenews_page__header_copy">
                                NewsUSA
                            </p>
                        </div>
                    </div>
                    <div className="content_wrap_wrapper">
                        <article className="page_article inthenews_article">
                            <div className="page_article__text">
                                <img className="page_article__image float" src={news} alt={''}  />
                                <p>
                                    The deal, which was later reported by major news media around the world, did not
                                    portend much
                                    initially. It was struck in 2005 between a high school dropout with a history of
                                    petty thefts and a
                                    little-known startup with no revenues. The latter wanted the former to spray
                                    paint graffiti on their
                                    office walls. The former brazenly asked for sixty thousand dollars -- an
                                    astronomical fee in
                                    graffiti business; the cash-strapped startup countered by offering him its
                                    shares, which had no
                                    market value.
                                </p>
                                <p>
                                    Seven years later, the company, no longer a startup but the largest social
                                    network, went public.
                                    That very day, the Facebook stock received by David Choe, the graffiti painter,
                                    turned into liquid
                                    assets valued at more than two hundred million dollars. As the stock price has
                                    been climbing,
                                    David's network exceeded half a billion dolars.
                                </p>
                                <p>
                                    This remarkable rags-to-riches story is now prominently featured on <span
                                    className="s4s">Stock4Services.com</span>,
                                    deployed by renowned KMGi Group to facilitate similar investments of services
                                    into emerging growth
                                    companies.
                                </p>
                                <p>
                                    "There's over a quarter of a trillion dollars per year of unsold advertising
                                    inventory in the USA
                                    alone, which could be invested into promising businesses," says Alex Konanykhin,
                                    CEO of KMGi "Yet
                                    broadcasters and publishers let it perish instead of investing in promising
                                    businesses that seek
                                    visibility. It's a colossal waste, and we seek to correct it, generating tens of
                                    billions of dollars
                                    of value."
                                </p>
                                <p>
                                    <span className="s4s">Stock4Services</span> also facilitates investments of
                                    other kinds of services,
                                    including online and outdoor advertising, visibility at major events, product
                                    placements, celebrity
                                    endorsements, public relations, programming, design and creative services. While
                                    no one can assure
                                    returns as high as in the Facebook example, investments can be quite secure,
                                    especially if made into
                                    VC-funded or public companies.
                                </p>
                                <p>
                                    "Companies invest trillions of dollars in the stock market," says Jay Baguioro,
                                    president of <span className="s4s">Stock4Services</span>. "We provide them with
                                    opportunities to invest their
                                    services, without having to sell them for cash first. It makes the market more
                                    efficient and allows
                                    for faster wealth accumulation and economic growth."
                                </p>
                                <p>
                                    Stock4Services' website lists some additional compelling examples that support
                                    their premise.
                                </p>
                                <p>
                                    Rapper 50 Cent accepted stock from the company behind Vitamin Water in exchange
                                    for his endorsement
                                    in 2004. When Coca-Cola bought the company four years later, his after-tax
                                    earnings exceeded $100
                                    million.
                                </p>
                                <p>
                                    The stock that actor William Shatner received in exchange for being a pitchman
                                    for Priceline in TV
                                    commercials has risen to over $600 million.
                                </p>
                                <p>
                                    Canadian geologist Chuck Fipke holds about a billion dollars in stock due to the
                                    work he's done
                                    uncovering diamonds in the country's Northwest Territories.
                                </p>
                                <p>
                                    And now <span className="s4s">Stock4Services</span> facilitates such deals to
                                    all service providers and
                                    advertising inventory owners. "We take a standard agency commission in the form
                                    of the stock of the
                                    transaction, so we only make money when our investors make money," points out
                                    Jay Baguioro. "It
                                    motivates us to seek investment opportunities with the highest and fastest
                                    return."
                                </p>
                            </div>
                        </article>
                    </div>
                    <div className="inthenews_page__header">
                        <div className="content_wrap_wrapper">
                            <h2 className="page_article__header">New company provides beneficial bridge to emerging businesses and service providers
                            </h2>
                            <p className="inthenews_page__header_copy">
                                By David Wilkening Editor, Chicago Star-Tribune For SyndicatedNews
                            </p>
                        </div>
                    </div>
                    <div className="content_wrap_wrapper">
                        <article className="page_article inthenews_article">
                            <div className="page_article__text">
                                <p>
                                    A new Manhattan-based company offers help to both cash-strapped start-ups and
                                    publishers with unsold
                                    ad space.
                                </p>
                                <p>
                                    The funding problem has long been a thorn in the side for startups. The odds of
                                    surviving the first
                                    two years in business are sometimes compared to the chances of surviving a trip over
                                    Niagara Falls
                                    in a barrel. “While poor management is cited most frequently as the reason
                                    businesses fail,
                                    inadequate or ill-timed financing is a close second,” says a U.S. Small Business
                                    Administration
                                    report.
                                </p>
                                <p>
                                    "At the same time, publishers often are faced with unsold ad space. Some publishers
                                    solve the
                                    problem by drastically reducing prices or giving ads away", says Raynaya Valles, a
                                    consultant who
                                    works with joint venture companies. “Let’s face it: if the ad is unused, you have
                                    made zero
                                    dollars,” he says.
                                </p>
                                <p>
                                    "We offer a publisher a way to accumulate equity in fast-growing companies in
                                    exchange for unsold
                                    advertising space. At the same time, emerging companies will be able to make their
                                    products known
                                    nationwide without any cash expenditure. It’s a mutually beneficial situation for
                                    everyone," says
                                    Alex Konanykhin, CEO of <span className="s4s">Stock4Services.com</span>.
                                </p>
                                <p>
                                    Registration in <span className="s4s">Stock4Services</span> is free for service
                                    providers and allows
                                    them to search the database of small cap public companies and promising start-ups to
                                    determine where
                                    they want to fuel growth in exchange for equity. “Our company lets many types of
                                    service providers
                                    leverage their excess capacity by exchanging it for valuable equity in emerging
                                    companies,” says
                                    Konanykhin. He points out that even a small piece of an early-stage venture can
                                    result in huge
                                    windfalls down the road.
                                </p>
                                <p>
                                    On the other end of business, at <span className="s4s">Stock4Services.com</span>,
                                    for a modest monthly
                                    fee, emerging companies can promote themselves among hundreds of media publishers
                                    and qualified
                                    service providers who are interested in trading ad space and other services in
                                    exchange for stock.
                                    Participants are guaranteed privacy and <span
                                    className="s4s">Stock4Services</span> pledges not to share
                                    their information with others.
                                </p>
                            </div>
                            <div className="inthenews_page__header">
                                <div className="content_wrap_wrapper">
                                    <h2 className="page_article__header upper"><span
                                        className="page_article__header_text">How to Beat a Recession</span></h2>
                                    <p className="inthenews_page__header_copy">
                                        Nevada Business Magazine
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="content_wrap_wrapper">
                        <article className="page_article inthenews_article">
                            <div className="page_article__text">
                                <p>
                                    The dreaded ‘R’ word is making the suppertime news, the dollar is in a free-fall and
                                    consumer
                                    spending is slowing to a crawl — a bad news combination that has struck fear into
                                    business owners
                                    across the nation.
                                </p>
                                <p>
                                    The good news is that there’s a silver lining in all the doom-and-gloom statistics:
                                    studies of
                                    previous recessions have shown that smart businesses can turn a bad economy into an
                                    advantage. The
                                    trick is not to panic.
                                </p>
                                <div>
                                    <p>Here’s how you can recession-proof your small business:</p>
                                    <ul className="inthenews_article__list">
                                        <li className="inthenews_article__list_item">
                                            Use high-return marketing techniques. While your competitors are slashing
                                            their advertising
                                            budgets, you are redirecting your resources to results-oriented campaigns
                                            such as public
                                            relations. Publicists will work to get your business in print, on radio and
                                            on TV at a
                                            fraction of the cost of an ad agency campaign. For example, <a
                                            href="http://publicityguaranteed.com" rel={'noreferrer'} target="_blank">Publicity
                                            Guaranteed</a> pioneered the pay-for-results-only PR system, saving small business owners
                                            thousands of
                                            dollars in fees they would have paid an ad agency for a campaign that failed
                                            to bring
                                            results.
                                        </li>
                                        <li className="inthenews_article__list_item">
                                            Spend smart. Look for innovative ways you can buy the services you need to
                                            succeed. For
                                            example, <span className="s4s">Stock4Services.com</span>, a “services for
                                            cash” facilitator,
                                            gives emerging businesses a vehicle with which to boost their profile — and
                                            their profits —
                                            without spending cash. Save money by using your company’s stock to acquire
                                            the nationwide
                                            advertising you need through the Stock4Services.com.
                                        </li>
                                        <li className="inthenews_article__list_item">
                                            Increase conversion rates on your website. Advertising and publicity allow
                                            your company to
                                            get noticed, but what happens once you get customers to your online site?
                                            Try increasing its
                                            effectiveness by adding interactive components to your website. For example,
                                            KMGI.com shows
                                            business owners how they can turn their websites into dynamic multimedia
                                            presentations of
                                            how their product works.
                                        </li>
                                        <li className="inthenews_article__list_item">
                                            Create a distinct advantage. In marketing terms, it’s called a Unique
                                            Selling Proposition,
                                            something that sets you apart from your competitor. Once you’ve decided
                                            which product or
                                            service would be considered essential during bad economic times — remember,
                                            consumers will
                                            cut out non-essentials — target your marketing efforts to your USP.
                                        </li>
                                        <li className="inthenews_article__list_item">
                                            Be an optimist. According to an old Chinese adage, “When strong winds are
                                            blowing, some hide
                                            behind walls and other build windmills.” Remember that many smart businesses
                                            became
                                            successful during market shake-downs caused by recessions.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inthenews_page__header">
                                <div className="content_wrap_wrapper">
                                    <h2 className="page_article__header upper"><span
                                        className="page_article__header_text">Boulder County Business Report</span>
                                    </h2>
                                    <p className="inthenews_page__header_copy">
                                        Business Report Staff
                                    </p>
                                </div>
                            </div>
                            <div className="content_wrap_wrapper">
                                <article className="page_article inthenews_article">
                                    <div className="page_article__text">
                                        <p>
                                            Attention startups: Can't afford to advertise?
                                        </p>
                                        <p>
                                            Attention publishers: Want to get in on some of the venture capital action?
                                        </p>
                                        <p>
                                            <span className="s4s">Stock4Services</span> can provide answers to these
                                            questions.
                                        </p>
                                        <p>
                                            Their service makes it possible for publishers to invest unsold advertising
                                            space into emerging
                                            companies that need to connect with a national audience. In return,
                                            publishers' equity investments
                                            will multiply once these companies achieve their full potential.
                                        </p>
                                        <p>
                                            Companies wanting to advertise join Services4Stock. Publishers search the
                                            Services4Stock database
                                            for companies they would like to invest in.
                                        </p>
                                        <p>
                                            Registering at <span className="s4s">Stock4Services.com</span> is free for
                                            publishers. <span className="s4s">Stock4Services</span> only receives a
                                            commission from a closed deal, in the form
                                            of stock from the same company.
                                        </p>
                                        <p>
                                            "Companies invest trillions of dollars in the stock market," says Alex
                                            Konanykhin, chief executive
                                            of the New York-based company. "We provide them with opportunities to invest
                                            services, without
                                            having to sell them for cash first. It makes the market more efficient and
                                            allows for faster wealth
                                            accumulation."
                                        </p>
                                    </div>
                                </article>
                            </div>
                            <div className="inthenews_page__header">
                                <div className="content_wrap_wrapper">
                                    <h2 className="page_article__header upper">Swap shop: exchanging equity for professional services</h2>
                                    <p className="inthenews_page__header_copy">
                                        Smarta
                                    </p>
                                </div>
                            </div>
                            <div className="content_wrap_wrapper">
                                <article className="page_article inthenews_article">
                                    <div className="page_article__text">
                                        <p className="bold"><strong>What happens when you give shares to another business?</strong></p>
                                        <p>
                                            Back in the halcyon days of the dotcom boom, a nice little trend evolved
                                            that cut through the airy,
                                            overinflated fads of the day. Start-ups reluctant or unable to sell great
                                            slabs of their equity to
                                            drooling venture capitalists instead gave shares to businesses that could
                                            actually help them grow,
                                            in return for that company's services. What usually happened was some
                                            creative spark with a big idea
                                            for a website gave shares to a development business. And the development
                                            team would work hard to
                                            build a top-notch site in the hope it would pump up the value of their
                                            shares. Things worked out
                                            pretty nicely for both parties: the business founder didn't have to spend a
                                            dime and the development
                                            company got a good chunk of equity in a potential next-big-thing.
                                        </p>
                                        <p>
                                            This idea of swapping equity for professional services outlived the dotcom
                                            boom, expanded to all
                                            types of B2B businesses, and is still going strong in the U.S. today.
                                            Websites such as <span className="s4s">Stock4Services.com</span> are
                                            dedicated to matching up suitable parties. Big
                                            advertising agencies and media companies have set up programs specifically
                                            to facilitate the
                                            exchanges. And you only have to browse top U.S. small-business websites <a
                                            href="http://www.entrepreneur.com/magazine/entrepreneur/2010/june/206608.html"
                                            target="_blank" rel={'noreferrer'} >Entrepreneur.com</a> and <a
                                            href="http://www.inc.com/magazine/20100501/trading-equity-for-services.html"
                                            target="_blank" rel={'noreferrer'} >Inc.com</a> to find examples of new businesses adopting the
                                            equity-for-professional-services model.
                                        </p>
                                    </div>
                                </article>
                            </div>
                            <div className="inthenews_page__header">
                                <div className="content_wrap_wrapper">

                                    <h2 className="page_article__header">D.C. Firms: It's Time To Take Stock Aversion to Trading Work For Client Equity Fading Fast

                                    </h2>
                                    <p className="inthenews_page__header_copy">
                                        Legal Times, By Vanessa Blum Monday, April 3, 2000
                                        <a className={"page_article__attachment pdf"} href={pdf} rel={'noreferrer'} target="_blank">Scanned
                                        version</a>

                                    </p>
                                </div>
                            </div>
                            <div className="content_wrap_wrapper">
                                <article className="page_article inthenews_article">
                                    <div className="page_article__text">
                                        <img className="page_article__image float" src={alexLena} alt={''}/>
                                            <p>
                                                Caption: Stocking Up: Arent Fox has entered a stock-for-fees deal with
                                                KMGI.com, founded by
                                                Alexandre Konanykhine and Elena Gratcheva (above). Partner Richard Gale
                                                (right) says such deals can
                                                have a “tremendous upside”.
                                            </p>
                                            <p>
                                                Excerpt:<br/>
                                                East Coast law firms once viewed owning stock in clients as a risky
                                                enterprise, limited to those
                                                crazy California lawyers and small-town practitioners.
                                            </p>
                                            <p>
                                                No more.
                                            </p>
                                            <p>
                                                As it turns out, those crazy firms in California haven't gotten sued.
                                                But they have gotten rich.
                                                This is, after all, how business is conducted in the New Economy. And
                                                D.C.-area firms are worrying
                                                less about getting too close to their clients and worrying more about
                                                being left behind in an
                                                increasingly competitive legal market.
                                            </p>
                                            <p>
                                                There are two major ways law firms can acquire stock in client companies
                                                - by paying cash like other
                                                institutional investors or by taking the more controversial path of
                                                trading their legal services.
                                                Local firms are suddenly doing both.
                                            </p>
                                            <p>
                                                Since January, D.C.'s Shaw Pittman; Hogan &amp; Hartson; Piper Marbury
                                                Rudnick &amp; Wolfe; and Akin, Gump,
                                                Strauss, Hauer &amp; Feld have launched venture investment funds for
                                                firm attorneys.
                                            </p>
                                            <p>
                                                At those firms and others, including D.C.'s Arent Fox Kintner
                                                Plotkin &amp; Kahn and Dow, Lohnes &amp;
                                                Albertson, partners have decided to aggressively pursue equity interests
                                                in clients in exchange for
                                                legal representation.
                                            </p>
                                            <p>
                                                "The attraction is obviously the tremendous upside you can realize if
                                                the company is successful,"
                                                says Arent Fox partner Richard Gale, chairman of the firm's financial
                                                management committee. "The
                                                firm can realize incredible returns, gains far greater than regular
                                                hourly fees."
                                            </p>
                                            <p>
                                                Case in point: In 1999, Palo Alto, Calif.'s Wilson Sonsini
                                                Goodrich &amp; Rosati saw its equity stake in
                                                client Webvan Group Inc. soar to $51 million on the company's first day
                                                of trading.
                                            </p>
                                            <p>
                                                Last week, Arent Fox, announced it was taking equity in New York-based
                                                start-up KMGI.com - a Web
                                                advertising company owned by Alexandre Konanykhine, Elena Gratcheva, and
                                                Nikolai Mentchoukov - in
                                                lieu of legal fees. About six similar deals are under review, according
                                                to firm chairman Marc
                                                Fleishaker.
                                            </p>
                                            <p>
                                                "It's really no different from taking a contingency case. If it works,
                                                then it comes out great, just
                                                like a contingency fee. And if it's not successful, you take a hit,"
                                                Fleishaker says. "If it's a
                                                transaction as opposed to litigation, I'm not sure that makes a
                                                difference."
                                            </p>
                                    </div>
                                </article>
                            </div>
                            <div className="inthenews_page__header">
                                <div className="content_wrap_wrapper">

                                    <h2 className="page_article__header">Equity For Time Growing number of local law firms taking equity in start-ups

                                    </h2>

                                    <p className="inthenews_page__header_copy">
                                        Washington Business Journal, Jake Richardson, Staff Reporter
                                        <a className={"page_article__attachment pdf"}
                                           href={pdf2}
                                           target="_blank" rel={'noreferrer'}>Scanned version</a>
                                    </p>
                                </div>
                            </div>
                            <div className="content_wrap_wrapper">
                                <article className="page_article inthenews_article">
                                    <div className="page_article__text">
                                        <img className="page_article__image float" src={alex} alt={''}/>
                                            <p>
                                                Caption: Alexandre Konanykhine, president of New York-based <a
                                                href="http://kmgi.com" target="_blank" rel={'noreferrer'}>KMGI.com</a>, agreed
                                                to give an undisclosed equity stake in his company to D.C. law firm
                                                Arent Fox Kitner.
                                            </p>
                                            <p>
                                                Excerpt: The Washington law firm is providing services for Konanykhine’s
                                                18-month-old start-up,
                                                KMGI.com, in exchange for equity in his company.
                                            </p>
                                            <p>
                                                Arent Fox and KMGI represent a growing group of start-ups and lawyers
                                                who are swapping stock in
                                                Internet start-ups - a commonplace practice among Silicon Valley firms -
                                                for legal services in the
                                                region.
                                            </p>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
    )
}
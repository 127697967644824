import * as React from "react";
import '../App.scss';
import '@csstools/normalize.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1113,
            xl: 1414,
        },
    },
});
export default function Aboutus(){
    return(
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" className={'static-page'}>
                <Header/>
                <Box className="page_article aboutus_article" maxWidth="lg">

                    <h2 className="page_article__header upper">
                        <span className="page_article__header_text">ADVERTISING, PR, LOBBYING, LEGAL SERVICES
                        </span>
                    </h2>
                    <h2 className="page_article__header upper" style={{marginBottom: '50px'}}>
                        <span className="page_article__header_text">— YOU CAN NOW OBTAIN IT ALL FOR STOCK</span>
                    </h2>
                    <div className="aboutus_article__video" >
                        <iframe width="320" height="320" src="//www.youtube.com/embed/_xxgecJ4fYY?rel=0&amp;showinfo=0"
                                frameBorder="0" allowFullScreen=""></iframe>
                    </div>
                    <div className="page_article__text">
                        <p>
                            <span className="s4s">Services4Stock</span> is a unique platform created to provide emerging
                            companies
                            with an easier way to finance their growth.
                        </p>
                        <p>
                            Typically, companies with high growth potential seek financing from venture capital firms or
                            investment banks. As many entrepreneurs can attest, that process is time-consuming,
                            frustrating, and
                            often fruitless.
                        </p>
                        <p>
                            Fortunately, there's an easier way. You can now expand your business by getting the help you
                            need
                            directly from the service providers. <span className="s4s">Services4Stock</span> facilitates
                            exactly
                            this kind of crowdfunding / crowdsourcing.
                        </p>
                        <p>
                            For example, if you want to make your product or service known nationwide, we can facilitate
                            deals
                            where major publishers and broadcasters give you millions of dollars of ad space in exchange
                            for
                            equity in your company.
                        </p>
                        <p>
                            This type of financing is easier to obtain than cash funding. Owners of excess advertising
                            inventory
                            have pressure to invest it before it perishes. For them, it's also an opportunity to grow
                            their
                            wealth faster by investing their services directly, without having to first sell them for
                            cash.
                        </p>
                        <p>
                            Similarly, many other service providers, such as online and outdoor media, trade shows,
                            celebrities,
                            PR firms, online visibility agencies, lawyers, sales experts, programmers, and many others
                            often
                            seek opportunities to grow their stock portfolio by investing their services into hot
                            start-ups or
                            fast-growing companies.
                        </p>
                        <p>
                            Simply present on <span className="s4s">Services4Stock.com</span> a strong case proving that
                            your stock
                            is a sound investment likely to generate a high ROI, and you will have a possibility of
                            securing,
                            without any cash payment, a website, a mobile application, a celebrity endorsement, a
                            high-profile
                            board member or nationwide advertising.
                        </p>
                        <p>
                            Sure, you may still need to go to venture capitalists for funding of some operational
                            expenses, but
                            the majority of your needs may be financed directly by service providers via <span
                            className="s4s">Services4Stock</span>
                            platform.
                        </p>
                        <p>
                            <span className="s4s">Services4Stock</span> can facilitate in-kind financing ranging between
                            $5,000 and
                            tens of millions of dollars, depending on the stage of your business and its valuation.
                        </p>
                        <p>
                            List your business on <span className="s4s">Services4Stock</span> today and we will get
                            right to work on
                            getting you the financing you need.
                        </p>
                    </div>

                </Box>
                <Footer/>
            </Container>
        </ThemeProvider>
        )
}
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import benefits_bg from '../img/benefits_bg.jpg';
import benefits1 from '../img/benefits/benefits1.png';
import benefits3 from '../img/benefits/benefits3.png';
import benefits4 from '../img/benefits/benefits4.png';
import benefits5 from '../img/benefits/benefit5.png';
import benefits6 from '../img/benefits/benefit6.png';
import benefits7 from '../img/benefits/benefit7.png';

export default function Benefits(){
    return(
        <Box className={'benefits__box'}>
            <h2><span>Benefits of</span> having your company on Services4Stock</h2>
            <Box className={"benefits__content"}>
                <img src={benefits_bg} alt=""/>
                <Box className={"benefits__text"}>
                    <List sx={{ width: '100%', maxWidth: 600, background: 'none' }}>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '58px'}} alt="" src={benefits7} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                          secondary={
                                              <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                            Don’t impact your cash flow; a very important resource at the early stage
                                            of an emerging business. Business services can be very expensive.
                                        </span>
                                              </React.Fragment>
                                          }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '48px'}} alt="" src={benefits1} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                         Safely work with service providers paying equity for expertise. An easier way
                                            to finance and accelerate the growth of your business with no cash needed.
                                        </span>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '52px'}} alt="" src={benefits3} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                          secondary={
                                              <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                           A unique way to turn a small piece of equity into a vehicle for your
                                            company’s future growth strategy.

                                        </span>

                                              </React.Fragment>
                                          }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '51px'}} alt="" src={benefits6} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className={'benefits__text-typografy'} >
                                           Select from a curated network of service providers and gain a high
                                            quality of service to achieve global exposure in multiple markets.
                                        </span>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '66px'}} alt="" src={benefits5} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                              secondary={
                                  <React.Fragment>
                                    <span className={'benefits__text-typografy'} >
                                        Your service provider will become a new partner that believes in your business
                                        model. They succeed only when you succeed.
                                    </span>
                                  </React.Fragment>
                              }
                            />
                        </ListItem>


                        <ListItem alignItems="flex-start" sx={{padding: 0, marginBottom: '30px'}}>
                            <ListItemAvatar className={'benefits__text-avatar'}>
                                <Avatar sx={{width: '56px'}} alt="" src={benefits4} />
                            </ListItemAvatar>
                            <ListItemText sx={{margin: 0}}
                                secondary={
                                    <React.Fragment>
                                        <span className="benefits__text-typografy" style={{maxWidth:'285px'}}>
                                           Reduce the risk of finding and acquiring outside funding in marketing, media
                                            promotion, IT solutions, Law firms, SaaS companies, and more.
                                        </span>

                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </List>

                </Box>
            </Box>
        </Box>
    )
};
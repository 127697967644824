import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import facebook from '../img/facebook.svg';
import insta from '../img/instagram.svg';
import twit from '../img/twitter.svg';
import linke from '../img/linkedin.svg';
import you from '../img/youtube.svg';

const scrollToDiv = id =>{
    let path = window.location.pathname;
    let ids = id;
    const divElement = document.getElementById(id);
    if(path === '/'){
        divElement.scrollIntoView({ behavior: 'smooth' });
    }else{
        window.location = '/?'+ ids ;
    }
}
export default function Footer() {
    return (
        <Box className={'footer__box'}>
            <Box className={'footer__top'}>
                <h2>Become an investor using idle resources and invest in vetted, innovative companies alongside leading industry experts and savvy venture capitalists</h2>
                <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">get started!</Button>
            </Box>

            <Box className={'copyrite_box'}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs="auto">
                        <a href='/'><img src={facebook} alt=""/></a>
                    </Grid>
                    <Grid item xs="auto">
                        <a href='/'><img src={insta} alt=""/></a>
                    </Grid>
                    <Grid item xs="auto">
                        <a href='/'><img src={twit} alt=""/></a>
                    </Grid>
                    <Grid item xs="auto">
                        <a href='/'><img src={linke} alt=""/></a>
                    </Grid>
                    <Grid item xs="auto">
                        <a href='/'><img src={you} alt=""/></a>
                    </Grid>
                </Grid>

                <p>2022 © Copyright Stock4Services</p>
            </Box>
        </Box>

    )
};
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import logo_mobile from '../img/logo-mobile.svg';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../img/logo.svg';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 47,
    height: 24,
    padding: 0,
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: 11,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,

        '&.Mui-checked': {
            transform: 'translateX(23px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#fff',

            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 20,
        height: 20,
        borderRadius: 10,
        boxShadow: 'none',
        backgroundImage: 'linear-gradient(to bottom, #ff9d00, #ffc600)',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 11,
        opacity: 1,
        backgroundColor: '#fff',
        boxSizing: 'border-box',
    },
}));
const scrollToDiv = id =>{
    let path = window.location.pathname;
    let ids = id;
    const divElement = document.getElementById(id);
    if(path === '/'){
        divElement.scrollIntoView({ behavior: 'smooth' });
    }else{
        window.location = '/?'+ ids ;
    }
}

export default function Header(){
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const clickSwitch = (event) =>{
        console.log(event.target.checked);
        if(event.target.checked === false){
            window.location = global.config.stock_site_link+'?modal';
        }
    }
    return(
        <Box sx={{maxWidth: '1115px', margin: '0 auto'}} className={'header'}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs="auto">
                    <h1>
                        <a href="/">
                            <img className={"logo"} src={logo} alt={'s4s'}/>
                            <img src={logo_mobile} className={"logo mobile"} alt={'s4s'}/>
                        </a>
                    </h1>
                </Grid>
                <Grid item xs="auto" className={'header__menu'} sx={{display: { xs: 'flex', md: 'none' }}}>
                    <IconButton
                        size="large"
                        className={'header__menu-icon'}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        className={"menu__box"}
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        <Link className={"menu__link"} onClick={() => scrollToDiv('why')} to="/" >Why?</Link>
                        <Link className={"menu__link"} onClick={() => scrollToDiv('how')} to="/" >How it Works?</Link>
                        <Link className={"menu__link"} to="/contact">Contact Us </Link>
                    </Menu>
                </Grid>
                <Grid item xl={5} lg={5} sm={5} className={'header__menu'} sx={{display: { xs: 'none', md: 'block' }}}>

                    <Box
                        className={"menu__box"}
                    >
                        <Link className={"menu__link"} onClick={() => scrollToDiv('why')} to="/" >Why?</Link>
                        <Link className={"menu__link"} onClick={() => scrollToDiv('how')} to="/" >How it Works?</Link>
                        <Link className={"menu__link"} to="/contact">Contact Us </Link>
                    </Box>
                </Grid>
                <Grid item xs="auto" className={'box-switch'}>
                    <Box className={"header__switch"}>
                        <span className={"header__switch-title"}>I am</span>
                        <Typography className={"no-active"}>Services Provider</Typography>
                        <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={clickSwitch}  />
                        <Typography className={"active"}>looking for services</Typography>
                    </Box>
                </Grid>
                <Grid item xs="auto" className={'box-switch-mobile'}>
                    <Box className={"header__switch"}>
                        <a className={"no-active"} href={global.config.stock_site_link}>i am Services Provider</a>
                        <Typography className={"active"}>i am looking for services</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
};
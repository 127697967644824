import React from 'react';
import ReactDOM from 'react-dom';
import './config';
import './index.css';
import App from './pages/Home';
import Contact from "./pages/contact";
import Aboutus from "./pages/Aboutus";
import Faq from "./pages/faq";
import Inthenews from "./pages/Inthenews";
import Termsofuse from "./pages/termsofuse";
import { Routes, Route, BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="contact" element={<Contact />} />
            <Route path="aboutus" element={<Aboutus />} />
            <Route path="faq" element={<Faq />} />
            <Route path="inthenews" element={<Inthenews />} />
            <Route path="termsofuse" element={<Termsofuse />} />
        </Routes>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
/*

 */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import why_bg from '../img/why_bg.jpg';
const scrollToDiv = id =>{
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: 'smooth' });
}

export default function Why(){
    return(
        <Box className={'why__box'} id="why">
            <Box className={"why__text"}>
                <h2>Why<br/> Services<span>4</span>Stock?</h2>
                <p>Manage your cash flow by leveraging your company’s valuable stock
                    in exchange for typically costly professional services. Join
                    the revolutionary new way that stocks are traded and
                    services delivered. </p>
            </Box>
            <img src={why_bg} alt=""/>
            <Box className={'why__img'}>&nbsp;</Box>
            <Button className={"btn"} onClick={() => scrollToDiv('form')} variant="contained">get started!</Button>

        </Box>
    )
};